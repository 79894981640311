import React, {useEffect, useState} from 'react';
import { Link, Route, Routes, Navigate, useNavigate, redirect} from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container,Box } from '@mui/material';
import SignUpUser from './SignUpUser';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import ReactUniqueViewTableTheme from './ReactUniqueViewTable';
import UserViewTableTheme from './UserViewTable';
import logo from "./assets/moon_logo.png";

const emailList = ['ayush731042@gmail.com', 'info@moontv.co', 'shilpipatni8@gmail.com'];

// Create a dark theme
const theme = createTheme({
    palette: {
      mode: 'dark', // Set theme mode to dark
      primary: {
        main: '#90caf9', // Adjust primary color for dark mode
      },
      background: {
        default: 'black', // Adjust background color for dark mode
        // paper: '#424242', // Adjust paper color for dark mode
      },
    },
  });

const AdminRoute1 = ({ isAdmin }) => {
    // console.log("AAAAAA1: " + isAdmin===true);
    if (isAdmin) {
        return <UserViewTableTheme/>;
    }
    return  <Navigate to="/" />;
};

const AdminRoute2 = ({ isAdmin }) => {
    // console.log("AAAAAA2: " + isAdmin===true);
    if (isAdmin) {
        return <SignUpUser/>;
    }
    return  <Navigate to="/" />;
};

const AdminRoute3 = ({ isAdmin }) => {
    // console.log("AAAAAA1: " + isAdmin===true);
    if (isAdmin) {
        return <Route path="/create-user" element={<AdminRoute2 isAdmin={isAdmin} />} />
        
    }
    return  <Route/>;
};

const AdminRoute4 = ({ isAdmin }) => {
    // console.log("AAAAAA2: " + isAdmin===true);
    if (isAdmin) {
        return <Route path="/create-user" element={<AdminRoute1 isAdmin={isAdmin} />} />
    }
    return  <Route/>;
};

  
const Dashboard = ({ user, onSignOut }) => {

    // const navigate = useNavigate();
    const [isAdmin, setAdmin] = useState(false);
    useEffect(() => {
        checkUser();
        redirect("/contentView");
      }, []);
    
    const checkUser = () => {
        if (emailList.includes(user.signInDetails.loginId)){
            setAdmin(true);
            return;
        }
        setAdmin(false);
    }

    const handleSignOut = async () => {
        try {
            await onSignOut();
            // console.log("SIGN OUT REDIRECT NEXT");
            // await navigate('/contentView');
            await redirect("/contentView");
            // navigate('/'); // Redirect to the home page or any other route\
            // console.log("Redirected");
        } catch (error) {
        console.error('Error signing out: ', error);
        }
    };

    return (
        <div>
    <ThemeProvider  theme={theme}>
        <AppBar position="sticky">
        <Toolbar>
            <Box
                component="img"
                sx={{
                height: 54,
                marginRight: 2,
                // marginLeft: 0,
                }}
                alt="Your logo."
                src={logo}
            />
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
           {user.signInDetails.loginId}
            </Typography>
            
            {/* <Container> */}
                <nav>
                    <Button component={Link} to="/create-user" variant="contained" color="inherit" sx={{ m: 1 }}>
                    Create User
                    </Button>
                    <Button component={Link} to="/contentView" variant="contained" color="inherit" sx={{ m: 1 }}>
                    Content Views
                    </Button>
                    <Button component={Link} to="/UserView" variant="contained" color="inherit" sx={{ m: 1 }}>
                    User Views
                    </Button>
                </nav>
                

            <Button color="inherit" onClick={handleSignOut}>
            Sign Out
            </Button>
        </Toolbar>
        </AppBar>
        </ThemeProvider>
        <Routes>
            <Route path="/contentView" element={<ReactUniqueViewTableTheme />} />
            {console.log("AYUHS admin: " + isAdmin)}
            {/* <AdminRoute /> */}
            {/* <Route path="/UserView" element={<AdminRoute1 isAdmin={isAdmin} />} />
            <Route path="/create-user" element={<AdminRoute2 isAdmin={isAdmin} />} /> */}
            {isAdmin?  <Route path="/UserView" element={<AdminRoute1 isAdmin={isAdmin} />} />: null}
            {isAdmin?  <Route path="/create-user" element={<AdminRoute2 isAdmin={isAdmin} />} />: null}
            
            {!isAdmin?  <Route path="/create-user" element={<Navigate to="/contentView" />} />: null}
            {!isAdmin?  <Route path="/UserView" element={<Navigate to="/contentView" />} />: null}
            {/* <AdminRoute3/>
            <AdminRoute4/> */}
            {/* {isAdmin && <Route path="/UserView" element={<UserViewTableTheme />} />}
            {isAdmin && <Route path="/create-user" element={<SignUpUser />} />} */}
                {/* {
                (emailList.includes(user.signInDetails.loginId)
                ( <Route path="/create-user" element={<SignUpUser />})
            } */}
            <Route path="*" element={<Navigate to="/contentView" />} />
        </Routes>
        
    </div>
    );
};

export default Dashboard;
