// import type { FormEvent } from "react"
import React, { useState } from "react";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Amplify } from "aws-amplify"
import { signUp } from "aws-amplify/auth"
import outputs from "./aws-cred.js"
import './createUser.css'
import Grid from '@mui/material/Grid';


import { Button, TextField, Box, Typography } from '@mui/material';
Amplify.configure(outputs)

// // Create a custom dark theme
// const theme = createTheme({
//   palette: {
//     mode: 'dark', // Set theme mode to dark
//     primary: {
//       main: '#90daf9', // Adjust primary color for dark mode
//     },
//   },
// });


const SignUpUser = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('Password@1234');
    const [message, setMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [resetForm, setResetForm] = useState(true);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

    const handleCreateUser = async () => {
        try {
            await signUp({
            username: email,
            password,
            attributes: {
                email
            }
            });
            setMessage( ` ${email} created successfully`);
            setEmail('');
            setPassword('Password@1234');
        } catch (error) {
            setMessage(`Error Creating User`);//error.message
        }
    };

  return (
    // <ThemeProvider theme={theme}>
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>

     
      <Grid item xs={12} sm={8} md={6} lg={4}>
  
        <Box component="form" color="primary">
          <TextField
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField 
            type={showPassword ? "text" : "password"}
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button onClick={togglePasswordVisibility} color="inherit">
            {showPassword ? 'Hide' : 'Show'}
          </Button>
          <Button onClick={handleCreateUser}  variant="contained"  fullWidth  sx={{
    backgroundColor: '#5e5e5e', // Custom background color
    color: 'white', // Text color
    '&:hover': {
      backgroundColor: '#303030', // Darker background color on hover
    },
  }}>
            Create User
          </Button>
          <p>{message}</p>
        </Box>
      </Grid>
    </Grid>    // </ThemeProvider>
  );
};
export default SignUpUser;





// function SignUpUser() {
//     const [email, setEmail] = useState('');
//     const [password, setPassword] = useState('Password@1234');
//     const [message, setMessage] = useState('');
//     const [showPassword, setShowPassword] = useState(false);
//     const togglePasswordVisibility = () => {
//         setShowPassword(!showPassword);
//       };

//     const handleCreateUser = async () => {
//         try {
//             await signUp({
//             username: email,
//             password,
//             attributes: {
//                 email
//             }
//             });
//             setMessage(`User created successfully`);
//         } catch (error) {
//             setMessage(`Error Creating User`);//error.message
//         }
//     };

//     return (
//         <div>
//           <h2>Create New User</h2>
//           <input
//             type="email"
//             placeholder="Email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//           />
//           <input
//             type={showPassword ? "text" : "password"}
//             placeholder="Password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//           />
//           <button onClick={togglePasswordVisibility}>
//             {showPassword ? 'Hide' : 'Show'}
//           </button>
//           <button onClick={handleCreateUser}>Create User</button>
//           <p>{message}</p>
//         </div>
//     );
// }


// export default CreateUserForm;
