import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import './table.css';


function ReactUniqueViewTable() {
    const columns  = [
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,
            wrap:true
        },
        {
            name: 'UniqueViews',
            selector: row => row.uniqueView,
            sortable: true,
            wrap:true
        },
        {
            name: 'UpdatedAt',
            selector: row => row.updatedAt? row.updatedAt.replace('T', '\n').replace('Z', ''): "",
            sortable: true,
            wrap:true
        }
    ];

    // const data = [
    //     {
    //         id: 1,
    //         title:'qq',
    //         uniqueView: '2',
    //         updatedAt:'2024-06-27T07:27:59Z'
    //     },
    //     {
    //         id: 2,
    //         title:'q',
    //         uniqueView: '2',
    //         updatedAt: '2024-06-26T07:27:59Z'
    //     },
    //     {
    //         id: 11,
    //         title:'aydush',
    //         uniqueView: '22',
    //         updatedAt:'2024-06-27T07:27:59Z'
    //     },
    //     {
    //         id: 22,
    //         title:'ayussh',
    //         uniqueView: '21',
    //         updatedAt: '2024-06-26T07:27:59Z'
    //     },
    //     {
    //         id: 13,
    //         title:'ayus5h',
    //         uniqueView: '25',
    //         updatedAt:'2024-06-27T07:27:59Z'
    //     },
    //     {
    //         id: 24,
    //         title:'ayus5h',
    //         uniqueView: '12',
    //         updatedAt: '2024-06-26T07:27:59Z'
    //     },
    //     {
    //         id: 54321,
    //         title:'ayush',
    //         uniqueView: '12',
    //         updatedAt:'2024-06-27T07:27:59Z'
    //     },
    //     {
    //         id: 213223,
    //         title:'ayush',
    //         uniqueView: '2',
    //         updatedAt: '2024-06-26T07:27:59Z'
    //     },
    //     {
    //         id: 2322,
    //         title:'ayush',
    //         uniqueView: '222',
    //         updatedAt: '2024-06-26T07:27:59Z'
    //     },
    //     {
    //         id: 1123,
    //         title:'ayush',
    //         uniqueView: '232',
    //         updatedAt:'2024-06-27T07:27:59Z'
    //     },
    //     {
    //         id: 2214,
    //         title:'ayush',
    //         uniqueView: '2',
    //         updatedAt: '2024-06-26T07:27:59Z'
    //     },
    //     {
    //         id: 5421,
    //         title:'ayush',
    //         uniqueView: '42',
    //         updatedAt:'2024-06-27T07:27:59Z'
    //     },
    //     {
    //         id: 21231,
    //         title:'ayush',
    //         uniqueView: '2',
    //         updatedAt: '2024-06-26T07:27:59Z'
    //     }
        
    // ]

  const [records, setRecords] = useState([]);
  const [filteredRecord, setFilteredRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetchData();
    
  }, []);

  const fetchData = async () => {
    try {
        const response = await fetch('https://9o2bndsf1b.execute-api.ap-southeast-2.amazonaws.com/prod/contentviews', 
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': '1zGn5s0eKq4nfHvIYG3HX8KQW5dc7hB87pQ4TQ9r'
                }
            }
        );

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      var dat = await response.json();
      dat = JSON.parse(dat.body);
    //   console.log("AAYUU " + dat);
      setRecords(dat);
      setFilteredRecords(dat);
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };
    function handleFilter(event) {
        if (event.target.value.toLowerCase() == "") {
            setFilteredRecords(records);
            return;
        }
        const newData = records.filter(row => {
            
            // console.log("AY "+ row);
            if (row.title && row.title.toLowerCase().includes(event.target.value.toLowerCase())) {
                return true;
            } 
            if (row.uniqueView && row.uniqueView == event.target.value) {
                return true;
            } 
            if (row.updatedAt && row.updatedAt.toLowerCase().includes(event.target.value.toLowerCase())) {
                return true;
            } 
            return false;
        })
        setFilteredRecords(newData)
    }

    return(
        <div>
            <div className='text-end'><input type="text" placeholder='Search'  onChange={handleFilter} /> </div>
            <DataTable
                columns={columns}
                data={filteredRecord}
                // selectableRows
                fixedHeader
                pagination                
                defaultSortFieldId={3}
                // defaultSortField="updatedAt"
                defaultSortAsc={false}
                highlightOnHover
                customStyles={{
                  cells: {
                    style: {
                      wordWrap: 'break-word',
                      whiteSpace: 'normal'
                    }
                  }
                }}
                
            />
        </div>
    );
}



const BuiltinStory = ({ theme }) => (
    <ReactUniqueViewTable/>
);


const Template = args => <BuiltinStory {...args} />;

export const ReactUniqueViewTableTheme = Template.bind({});

ReactUniqueViewTableTheme.args = {
    theme: 'default',
};


export default ReactUniqueViewTableTheme;