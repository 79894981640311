import './App.css';
import { Amplify } from 'aws-amplify';
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-cred';
import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Dashboard from './Dashboard';
import { useNavigate } from 'react-router-dom';

// import { fetchAuthSession } from 'aws-amplify/auth';

import AuthenticatedComponent from './AuthenticatedComponent';
Amplify.configure(awsExports);

export function App() {
  // const navigate = useNavigate();

  return (
    <div className="App">
      <header className="App-header">
        
        <Authenticator  hideSignUp={true}>
          {({ signOut, user }: WithAuthenticatorProps) => (
            <main>
              <div className="App">
                <header className="App-header">
                  {console.log("APPJS-AYUSH: "+ JSON.stringify(user, null, 2))}
               {user ? 
                      (
                        <Router>
                          <Dashboard user={user} onSignOut={signOut}/>
                        </Router>
                        ) : (
                        <p>Please sign in</p>
                      )}                          
                </header>
              </div>
            </main>
          )}
        </Authenticator>
      </header>
    </div>
  );
}



export default withAuthenticator(App, { hideSignUp: true });
