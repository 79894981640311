import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
// import DataTableExtensions from "react-data-table-component-extensions";
import './table.css';


// import Card from '@material-ui/core/Card';
// import IconButton from '@material-ui/core/IconButton';
// import ArrowDownward from '@material-ui/icons/ArrowDownward';
// const sortIcon = <ArrowDownward />;
//-----------



function UserViewTable() {

    const caseInsensitiveSort = (rowA, rowB) => {
        const a = rowA.title.toLowerCase();
        const b = rowB.title.toLowerCase();
    
        if (a > b) {
            return 1;
        }
    
        if (b > a) {
            return -1;
        }
    
        return 0;
    };


    const columns  = [
        {
            name: 'Name',
            selector: row => {
                console.log(row);
                return row.name;
            },
            sortable: true,
            wrap:true
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            wrap:true
        },
        {
            name: 'Mobile',
            selector: row => row.mobile,
            sortable: true,
            wrap:true
        },
        {
            name: '1. Title',
            selector: row => !row.contentIds? "": row.contentIds.length > 0? row.contentIds[0].title: "" ,
            sortable: true,
            wrap:true
        },
        {
            name: '1. UpdatedAt',
            selector: row => !row.contentIds? "" :row.contentIds.length > 0? !row.contentIds[0].updatedAt? "": row.contentIds[0].updatedAt.replace('T', '\n').replace('Z', ''): "" ,
            sortable: true,
            wrap:true
        },
        {
            name: '2. Title',
            selector: row => row.contentIds? row.contentIds.length > 1? row.contentIds[1].title: "" : "",
            sortable: true,
            wrap:true
        },
        {
            name: '2. UpdatedAt',
            selector: row => !row.contentIds? "" :row.contentIds.length > 1? !row.contentIds[1].updatedAt? "": row.contentIds[1].updatedAt.replace('T', '\n').replace('Z', ''): "" ,
            sortable: true,
            wrap:true
        },
        {
            name: '3. Title',
            selector: row => !row.contentIds? "" :row.contentIds.length > 2? row.contentIds[2].title: "" ,
            sortable: true,
            wrap:true
        },
        {
            name: '3. UpdatedAt',
            selector: row => !row.contentIds? "" :row.contentIds.length > 2? !row.contentIds[2].updatedAt? "": row.contentIds[2].updatedAt.replace('T', '\n').replace('Z', ''): "",
            sortable: true,
            wrap:true
        },
        {
            name: 'Country',
            selector: row => row.country,
            sortable: true,
            wrap:true
        },
        {
            name: 'CreatedAt',
            selector: row => !row.createdAt?  "": row.createdAt.replace('T', '\n').replace('Z', ''),
            sortable: true,
            wrap:true
        }
    ];
        /*mobile userId 
  contentIds  description _id title updatedAt
  createdAt  email  country name */

    const data = [
        {
            id: 1,
            title:'qq',
            uniqueView: '2',
            updatedAt:'2024-06-27T07:27:59Z'
        },
        {
            id: 2,
            title:'q',
            uniqueView: '2',
            updatedAt: '2024-06-26T07:27:59Z'
        },
        {
            id: 11,
            title:'aydush',
            uniqueView: '22',
            updatedAt:'2024-06-27T07:27:59Z'
        },
        {
            id: 22,
            title:'ayussh',
            uniqueView: '21',
            updatedAt: '2024-06-26T07:27:59Z'
        },
        {
            id: 13,
            title:'ayus5h',
            uniqueView: '25',
            updatedAt:'2024-06-27T07:27:59Z'
        },
        {
            id: 24,
            title:'ayus5h',
            uniqueView: '12',
            updatedAt: '2024-06-26T07:27:59Z'
        },
        {
            id: 54321,
            title:'ayush',
            uniqueView: '12',
            updatedAt:'2024-06-27T07:27:59Z'
        },
        {
            id: 213223,
            title:'ayush',
            uniqueView: '2',
            updatedAt: '2024-06-26T07:27:59Z'
        },
        {
            id: 2322,
            title:'ayush',
            uniqueView: '222',
            updatedAt: '2024-06-26T07:27:59Z'
        },
        {
            id: 1123,
            title:'ayush',
            uniqueView: '232',
            updatedAt:'2024-06-27T07:27:59Z'
        },
        {
            id: 2214,
            title:'ayush',
            uniqueView: '2',
            updatedAt: '2024-06-26T07:27:59Z'
        },
        {
            id: 5421,
            title:'ayush',
            uniqueView: '42',
            updatedAt:'2024-06-27T07:27:59Z'
        },
        {
            id: 21231,
            title:'ayush',
            uniqueView: '2',
            updatedAt: '2024-06-26T07:27:59Z'
        }
        
    ]

    const [records, setRecords] = useState([]);
    const [filteredRecord, setFilteredRecords] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
        const response = await fetch('https://9o2bndsf1b.execute-api.ap-southeast-2.amazonaws.com/prod/userviews', 
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': '1zGn5s0eKq4nfHvIYG3HX8KQW5dc7hB87pQ4TQ9r'
                }
            }
        );

      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      var dat = await response.json();
      dat = JSON.parse(dat.body);
      setFilteredRecords(dat);
      setRecords(dat);
      
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

    function handleFilter(event) {
        if (event.target.value.toLowerCase() == "") {
            setFilteredRecords(records);
            return;
        }
        const newData = records.filter(row => {
            if (row.name && row.name.toLowerCase().includes(event.target.value.toLowerCase())) {
                return true;
            }
            if(row.mobile && row.mobile.toLowerCase().includes(event.target.value.toLowerCase()))
                return true;
            if(row.createdAt && row.createdAt.toLowerCase().includes(event.target.value.toLowerCase()))
                return true;
            if(row.email && row.email.toLowerCase().includes(event.target.value.toLowerCase()))
                return true;
            if(row.country && row.country.toLowerCase().includes(event.target.value.toLowerCase()))
                return true;
            
            if(row.contentIds && row.contentIds.length > 0 && row.contentIds[0].title && row.contentIds[0].title.toLowerCase().includes(event.target.value.toLowerCase()))
                return true;
            if(row.contentIds && row.contentIds.length > 0 && row.contentIds[0].updatedAt && row.contentIds[0].updatedAt.toLowerCase().includes(event.target.value.toLowerCase()))
                return true;

            if(row.contentIds && row.contentIds.length > 1 && row.contentIds[1].title && row.contentIds[1].title.toLowerCase().includes(event.target.value.toLowerCase()))
                return true;
            if(row.contentIds && row.contentIds.length > 1 && row.contentIds[1].updatedAt && row.contentIds[1].updatedAt.toLowerCase().includes(event.target.value.toLowerCase()))
                return true;

            if(row.contentIds && row.contentIds.length > 2 && row.contentIds[2].title && row.contentIds[2].title.toLowerCase().includes(event.target.value.toLowerCase()))
                return true;
            if(row.contentIds && row.contentIds.length > 2 && row.contentIds[2].updatedAt && row.contentIds[2].updatedAt.toLowerCase().includes(event.target.value.toLowerCase()))
                return true;

            return false;
        })
        setFilteredRecords(newData)
    }

    return (
        <div>
            <div className='text-end'><input type="text" placeholder='Search'  onChange={handleFilter} /> </div>
            <DataTable
                columns={columns}
                data={filteredRecord}
                selectableRows
                fixedHeader
                pagination                
                defaultSortFieldId={9}
                // defaultSortField="updatedAt"
                defaultSortAsc={false}
                highlightOnHover
                customStyles={{
                  cells: {
                    style: {
                      wordWrap: 'break-word',
                      whiteSpace: 'normal'
                    }
                  }
                }}

            />
        </div>
    );
}
//-------



const BuiltinStory = ({ theme }) => (
    <UserViewTable/>
);


const Template = args => <BuiltinStory {...args} />;

export const UserViewTableTheme = Template.bind({});

UserViewTableTheme.args = {
    theme: 'default',
};

export default UserViewTableTheme;